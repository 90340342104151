<template>
    <div>
        <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增门店信息' : '修改门店信息'"
            :close-on-click-modal="false" @closed="cancel" width="1200px">
            <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="130px" label-suffix=":"
                v-if="form" :disabled="disabled">
                <!-- v-if="erpType!=='serta'" $erp.enable()未true 可以修改-->
                <div v-if="checkPermission(['SHOPMANAGE_ALL', 'SHOPMANAGE_ADD'])">
                    <el-tabs v-model="activeName" tab-position="left">
                        <el-tab-pane label="门店信息" name="1">
                            <el-collapse v-model="baseNews">
                                <el-collapse-item title="基本信息" name="first">
                                    <template v-if="viewStore">
                                        <div class="h">
                                            <el-form-item label="选择经销商" prop="entId">
                                                <quick-select v-model="form.entId" url="api/distributor"
                                                    placeholder="经销商" clearable filterable value-field="enterpriseId"
                                                    style="width: 575px" />
                                            </el-form-item>
                                            <el-form-item label="是否启用" prop="enabled">
                                                <el-switch v-model="form.enabled" active-color="#13ce66" />
                                            </el-form-item>
                                        </div>
                                    </template>
                                    <div class="h">
                                        <el-form-item label="所属物业" prop="propertyName">
                                            <el-input v-model="form.propertyName" style="width: 575px;" />
                                        </el-form-item>
                                        <el-form-item label="状态" prop="status">
                                            <!-- <div v-if="form.status">{{ form.status }}</div>
                                            <div style="width: 220px;" v-else><el-tag type="success">已开店</el-tag></div> -->
                                            <el-input v-model="form.status" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h sb">
                                        <template v-if="$erp.enable()">
                                            <template v-if="!viewStore">
                                                <el-form-item label="ERP账套" prop="erpAccountId">
                                                    <quick-select v-model="form.erpAccountId" value-field="id"
                                                        filterable url="api/shop/erp/account" placeholder="选择ERP账套"
                                                        style="width: 220px;" />
                                                </el-form-item>
                                                <el-form-item prop="erpCode" label="ERP编码">
                                                    <el-input v-model="form.erpCode" :maxlength="40"
                                                        @keypress.enter.native="handleQueryByERP"
                                                        @change="handleQueryByERP" :readonly="searching"
                                                        style="width: 220px;">
                                                        <el-button slot="append" icon="el-icon-search"
                                                            v-loading="searching" :disabled="!form.erpCode"
                                                            @click="handleQueryByERP"></el-button>
                                                    </el-input>
                                                </el-form-item>
                                                <el-form-item label="区域" prop="areaId">
                                                    <tree-picker v-model="form.areaId" url="api/area" flat
                                                        style="width: 220px;" />
                                                </el-form-item>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="h sb">
                                        <el-form-item label="门店编码" prop="code">
                                            <el-input v-model="form.code" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="门店名称" prop="name">
                                            <el-input v-model="form.name" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="门店类型" prop="storeType">
                                            <el-input v-model="form.storeType" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h sb">
                                        <el-form-item label="门店位置等级" prop="locationType">
                                            <el-input v-model="form.locationType" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="商场名称" prop="mall">
                                            <el-input v-model="form.mall" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="商场类型" prop="mallType">
                                            <el-input v-model="form.mallType" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h sb">
                                        <el-form-item label="商场位置等级" prop="mallLocationType">
                                            <el-input v-model="form.mallLocationType" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="地点标识" prop="locationDesc">
                                            <el-input v-model="form.locationDesc" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="装修类型" prop="decorateType">
                                            <el-input v-model="form.decorateType" style="width: 220px;" />
                                        </el-form-item>
                                    </div>


                                    <div class="h sb">
                                        <el-form-item label="门店申请类型" prop="applyType">
                                            <el-input v-model="form.applyType" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="门店申请日期" prop="applyDate">
                                            <el-date-picker v-model="form.applyDate" value-format="timestamp"
                                                style="width: 220px" />
                                        </el-form-item>
                                        <el-form-item label="计划开店日期" prop="planOpenDate">
                                            <el-date-picker v-model="form.planOpenDate" value-format="timestamp"
                                                style="width: 220px" />
                                        </el-form-item>
                                    </div>
                                    <div class="h">
                                        <el-form-item label="门店形象" prop="storeDesign">
                                            <el-input v-model="form.storeDesign" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="综合形象" prop="overallDesign">
                                            <el-input v-model="form.overallDesign" style="width: 575px;" />
                                        </el-form-item>


                                    </div>
                                    <div class="h">
                                        <el-form-item label="门店申请总面积" prop="builtArea">
                                            <div class="h c" style="width: 220px;">
                                                <el-input-number v-model="form.builtArea" :min="0" :max="99999"
                                                    :step="1" :precision="2" size="mini" controls-position="right"
                                                    class="flex" />
                                                <span class="no-flex">&nbsp;㎡（建筑面积）</span>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="区域" prop="areaName" v-if="!$erp.enable()">
                                            <el-input v-model="form.areaName" style="width: 575px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h c">
                                        <el-form-item label="门店详细地址">
                                            <quick-select v-model="form.provinceCode" :label.sync="form.provinceName"
                                                url="api/regionalDict/province" value-field="code" placeholder="选择省份"
                                                filterable style="width: 165px;"
                                                @change="form.cityCode = null; form.districtCode = null;" />
                                        </el-form-item>
                                        <el-form-item label-width="10px" prop="cityCode">
                                            <quick-select v-model="form.cityCode" :label.sync="form.cityName"
                                                :url="`api/regionalDict/parent/${form.provinceCode}`" value-field="code"
                                                placeholder="选择城市" filterable v-if="form.provinceCode"
                                                style="width: 165px;" @change="form.districtCode = null" />
                                            <el-input disabled placeholder="请先选择省份" v-else style="width: 165px;" />
                                        </el-form-item>
                                        <el-form-item label-width="10px" prop="districtCode">
                                            <quick-select v-model="form.districtCode" :label.sync="form.districtName"
                                                :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code"
                                                placeholder="选择地区" filterable v-if="form.cityCode"
                                                style="width: 165px;" />
                                            <el-input disabled placeholder="请先选择城市" v-else style="width: 165px;" />
                                        </el-form-item>
                                        <el-form-item label-width="10px" prop="address" class="flex">
                                            <el-input v-model="form.address" style="width: 100%;" placeholder="具体到楼层门牌号"
                                                class="flex" />
                                        </el-form-item>

                                    </div>

                                    <div class="h">
                                        <el-form-item label="经度" prop="lng">
                                            <el-input v-model="form.lng" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="纬度" prop="lat">
                                            <el-input v-model="form.lat" style="width: 220px;" />
                                        </el-form-item>

                                    </div>
                                    <div class="h">
                                        <el-form-item label="城市类型" prop="cityType">
                                            <el-input v-model="form.cityType" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="区县类型" prop="districtType">
                                            <el-input v-model="form.districtType" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="门店月租金" prop="rent">
                                            <el-input-number v-model="form.rent" :min="0" :max="99999" :step="1"
                                                :precision="2" size="mini" controls-position="right"
                                                style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h">
                                        <el-form-item label="拓展人员" prop="expandPerson">
                                            <el-input v-model="form.expandPerson" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="运营人员" prop="managePerson">
                                            <el-input v-model="form.managePerson" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h">
                                        <el-form-item label="提供除螨服务" prop="removeMiteService" style="width: 350px;">
                                            <el-switch v-model="form.removeMiteService" />
                                        </el-form-item>
                                        <el-form-item label="除螨联系人" prop="removeMiteContact" v-if="form.removeMiteService">
                                            <el-input v-model="form.removeMiteContact" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="除螨联系电话" prop="removeMiteTelephone" v-if="form.removeMiteService">
                                            <el-input v-model="form.removeMiteTelephone" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="开业" name="second">
                                    <el-form-item label="店面人员是否已参与公司培训" prop="isTrained" label-width="230px">
                                        <el-switch v-model="form.isTrained" />
                                    </el-form-item>

                                    <div class="h sb">
                                        <el-form-item label="导购配备人数" prop="salesmanNumber">
                                            <el-input-number v-model="form.salesmanNumber" :min="0" :max="99999"
                                                :step="1" :precision="0" size="mini" controls-position="right"
                                                style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="预计开业活动日期" prop="openPromotionDate">
                                            <el-date-picker v-model="form.openPromotionDate" value-format="timestamp"
                                                style="width: 220px" />
                                        </el-form-item>
                                    </div>

                                    <div class="h sb">
                                        <!-- <el-form-item label="店面开业面积" prop="useArea">
                                          <el-input-number v-model="form.useArea" :min="0" :max="99999" :step="1"
                                              :precision="2" size="mini" controls-position="right"
                                              style="width: 220px;" />（使用面积）
                                      </el-form-item> -->


                                        <el-form-item label="店面开业面积" prop="useArea">
                                            <div class="h c" style="width: 220px;">
                                                <el-input-number v-model="form.useArea" :min="0" :max="99999" :step="1"
                                                    :precision="2" size="mini" controls-position="right" class="flex" />
                                                <span class="no-flex">&nbsp;㎡（使用面积）</span>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="参与培训方式" prop="trainingMode">
                                            <el-input v-model="form.trainingMode" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item title="验收" name="third">

                                    <div class="h sb">
                                        <el-form-item label="开业日期" prop="openDate">
                                            <el-date-picker v-model="form.openDate" value-format="timestamp"
                                                style="width: 220px" />
                                        </el-form-item>


                                    </div>
                                    <div class="h sb">
                                        <el-form-item label="店内装修得分" prop="indoorScore">
                                            <el-input-number v-model="form.indoorScore" :min="0" :max="99999" :step="1"
                                                :precision="2" size="mini" controls-position="right"
                                                style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="独立外立面得分" prop="outdoorScore">
                                            <el-input-number v-model="form.outdoorScore" :min="0" :max="99999" :step="1"
                                                :precision="2" size="mini" controls-position="right"
                                                style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    <div class="h sb">
                                        <el-form-item label="门店验收面积" prop="checkArea">
                                            <div class="h c" style="width: 220px;">
                                                <el-input-number v-model="form.checkArea" :min="0" :max="99999"
                                                    :step="1" :precision="2" size="mini" controls-position="right"
                                                    class="flex" />
                                                <span class="no-flex">&nbsp;㎡（使用面积）</span>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="独立外立面面积" prop="outdoorArea">
                                            <el-input-number v-model="form.outdoorArea" :min="0" :max="99999" :step="1"
                                                :precision="2" size="mini" controls-position="right"
                                                style="width: 220px;" />
                                        </el-form-item>
                                    </div>


                                </el-collapse-item>
                                <el-collapse-item title="关店" name="fourth">
                                    <div class="h sb">
                                        <el-form-item label="门店原名称" prop="oldName">
                                            <el-input v-model="form.oldName" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="原门店编码" prop="oldCode">
                                            <el-input v-model="form.oldCode" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="原门店形象" prop="oldStoreDesign">
                                            <el-input v-model="form.oldStoreDesign" style="width: 220px;" />
                                        </el-form-item>

                                    </div>
                                    <div class="h">
                                        <el-form-item label="原综合形象" prop="oldOverallDesign">
                                            <el-input v-model="form.oldOverallDesign" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="原门店面积" prop="oldUseArea">
                                            <el-input-number v-model="form.oldUseArea" :min="0" :max="99999" :step="1"
                                                :precision="2" size="mini" controls-position="right"
                                                style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="原门店详细地址" prop="oldAddress">
                                            <el-input v-model="form.oldAddress" style="width: 220px;" />
                                        </el-form-item>

                                    </div>
                                    <div class="h">
                                        <el-form-item label="关店日期" prop="closeDate">
                                            <el-date-picker v-model="form.closeDate" value-format="timestamp"
                                                style="width: 220px" />
                                        </el-form-item>
                                        <el-form-item label="关店类型" prop="closeType">
                                            <el-input v-model="form.closeType" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="关店原因" prop="closeDesc">
                                            <el-input v-model="form.closeDesc" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </el-tab-pane>
                        <el-tab-pane label="授权明细" name="2">
                            <el-table key="authDetail_0" :data="items || []" border style="width: 600px;">
                                <el-table-column prop="createAt" label="创建日期"
                                    :formatter="v => { return v.createAt ? new Date(v.createAt).format('yyyy/MM/dd') : '' }" />
                                <el-table-column prop="authBeginDate" label="授权开始日期 ~ 授权结束日期"
                                    :formatter="v => { return v.authBeginDate ? new Date(v.authBeginDate).format('yyyy/MM/dd') : '' }">
                                    <template slot-scope="scope">
                                        {{ new Date(scope.row.authBeginDate).format('yyyy/MM/dd') }} ~ {{
                                            new
                                                                                Date(scope.row.authEndDate).format('yyyy/MM/dd')
                                        }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="关联仓库" name="3" v-if="!viewStore">
                            <el-form-item label="仓库" prop="warehouseName">
                                <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName"
                                    url="api/warehouse/all" placeholder="选择仓库" clearable filterable
                                    style="width: 220px;" />
                            </el-form-item>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div v-else>
                    <template v-if="!$erp.enable()">
                        <el-tabs v-model="activeName" tab-position="left">
                            <el-tab-pane label="门店信息" name="1">
                                <el-collapse v-model="baseNews">
                                    <el-collapse-item title="基本信息" name="first">
                                        <div class="h">
                                            <el-form-item label="所属物业" prop="propertyName">
                                                <div style="width: 575px;">{{ form.propertyName }}</div>
                                            </el-form-item>
                                            <el-form-item label="状态" prop="enabled">
                                                <div v-if="form.status">{{ form.status }}</div>
                                                <div style="width: 220px;" v-else><el-tag type="success">已开店</el-tag>
                                                </div>
                                            </el-form-item>

                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店编码" prop="code">
                                                <div style="width: 220px;">{{ form.code }}</div>
                                            </el-form-item>
                                            <el-form-item label="门店名称" prop="name">
                                                <div style="width: 220px;">{{ form.name }}</div>
                                            </el-form-item>
                                            <el-form-item label="门店类型" prop="storeType">
                                                <div style="width: 220px;">{{ form.storeType }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店位置等级" prop="locationType">
                                                <div style="width: 220px;">{{ form.locationType }}</div>
                                            </el-form-item>
                                            <el-form-item label="商场名称" prop="mall">
                                                <div style="width: 220px;">{{ form.mall }}</div>
                                            </el-form-item>
                                            <el-form-item label="商场类型" prop="mallType">
                                                <div style="width: 220px;">{{ form.mallType }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="商场位置等级" prop="mallLocationType">
                                                <div style="width: 220px;">{{ form.mallLocationType }}</div>
                                            </el-form-item>
                                            <el-form-item label="地点标识" prop="locationDesc">
                                                <div style="width: 220px;">{{ form.locationDesc }}</div>
                                            </el-form-item>
                                            <el-form-item label="装修类型" prop="decorateType">
                                                <div style="width: 220px;">{{ form.decorateType }}
                                                </div>
                                            </el-form-item>
                                        </div>

                                        <div class="h sb">
                                            <el-form-item label="门店申请类型" prop="applyType">
                                                <div style="width: 220px;">{{ form.applyType }}</div>
                                            </el-form-item>
                                            <el-form-item label="门店申请日期" prop="applyDate">
                                                <div style="width: 220px;">{{
                                                    form.applyDate && new
                                                        Date(form.applyDate).format('yyyy/MM/dd')
                                                }}
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="计划开店日期" prop="planOpenDate">
                                                <div style="width: 220px;">{{
                                                    form.planOpenDate && new
                                                        Date(form.planOpenDate).format('yyyy/MM/dd')
                                                }}
                                                </div>
                                            </el-form-item>
                                        </div>
                                        <div class="h">
                                            <el-form-item label="门店形象" prop="storeDesign">
                                                <div style="width: 220px;">{{ form.storeDesign }}</div>
                                            </el-form-item>
                                            <el-form-item label="综合形象" prop="overallDesign">
                                                <div style="width: 575px;">{{ form.overallDesign }}</div>
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="门店申请总面积" prop="builtArea">
                                                <div style="width: 220px;">{{ form.builtArea }}㎡（建筑面积）</div>
                                            </el-form-item>
                                            <el-form-item label="区域" prop="areaName">
                                                <div style="width: 575px;">{{ form.areaName }}</div>
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="门店详细地址" prop="address">
                                                <div style="width: 220px;">{{ form.address }}</div>
                                            </el-form-item>
                                            <el-form-item label="经度" prop="lng">
                                                <div style="width: 220px;">{{ form.lng }}</div>
                                            </el-form-item>
                                            <el-form-item label="纬度" prop="lat">
                                                <div style="width: 220px;">{{ form.lat }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="城市类型" prop="cityType">
                                                <div style="width: 220px;">{{ form.cityType }}</div>
                                            </el-form-item>
                                            <el-form-item label="区县类型" prop="districtType">
                                                <div style="width: 220px;">{{ form.districtType }}</div>
                                            </el-form-item>

                                            <el-form-item label="门店月租金" prop="rent">
                                                <div style="width: 220px;">{{ form.rent }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h">
                                            <el-form-item label="运营人员" prop="managePerson">
                                                <div style="width: 220px;">{{ form.managePerson }}</div>
                                            </el-form-item>
                                            <el-form-item label="拓展人员" prop="expandPerson">
                                                <div style="width: 220px;">{{ form.expandPerson }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h">
                                            <el-form-item label="提供除螨服务" prop="removeMiteService">                                                
                                                <div style="width: 220px;">{{ form.removeMiteService == true ? "是" : '否' }}</div>
                                            </el-form-item>
                                            <el-form-item label="除螨联系人" prop="removeMiteContact" v-if="form.removeMiteService">                                                
                                                <div style="width: 220px;">{{ form.removeMiteContact }}</div>
                                            </el-form-item>
                                            <el-form-item label="除螨联系电话" prop="removeMiteTelephone" v-if="form.removeMiteService">
                                                <div style="width: 220px;">{{ form.removeMiteTelephone }}</div>                                                
                                            </el-form-item>
                                        </div>

                                    </el-collapse-item>
                                    <el-collapse-item title="开业" name="second">
                                        <el-form-item label="店面人员是否已参与公司培训" prop="isTrained" label-width="220px">
                                            <div style="width: 220px;">{{ form.isTrained == true ? "是" : '否' }}</div>
                                        </el-form-item>
                                        <div class="h sb">
                                            <el-form-item label="导购配备人数" prop="salesmanNumber">
                                                <div style="width: 220px;">{{ form.salesmanNumber }}</div>
                                            </el-form-item>
                                            <el-form-item label="预计开业活动日期" prop="openPromotionDate">
                                                <div style="width: 220px;">{{
                                                    form.openPromotionDate && new
                                                        Date(form.openPromotionDate).format('yyyy/MM/dd')
                                                }}
                                                </div>
                                            </el-form-item>
                                        </div>

                                        <div class="h sb">
                                            <el-form-item label="店面开业面积" prop="useArea">
                                                <div style="width: 220px;">{{ form.useArea }}㎡（使用面积）</div>
                                            </el-form-item>
                                            <el-form-item label="参与培训方式" prop="trainingMode">
                                                <div style="width: 220px;">{{ form.trainingMode }}</div>
                                            </el-form-item>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="验收" name="third">
                                        <div class="h sb">
                                            <el-form-item label="开业日期" prop="openDate">
                                                <div style="width: 220px;">{{
                                                    form.openDate && new
                                                        Date(form.openDate).format('yyyy/MM/dd')
                                                }}
                                                </div>
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="店内装修得分" prop="indoorScore">
                                                <div style="width: 220px;">{{ form.indoorScore }}</div>
                                            </el-form-item>
                                            <el-form-item label="独立外立面得分" prop="outdoorScore">
                                                <div style="width: 220px;">{{ form.outdoorScore }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店验收面积" prop="checkArea">
                                                <div style="width: 220px;">{{ form.checkArea }}㎡（使用面积）</div>
                                            </el-form-item>
                                            <el-form-item label="独立外立面面积" prop="outdoorArea" label-width="200px">
                                                <div style="width: 220px;">{{ form.outdoorArea }}㎡（使用面积）</div>
                                            </el-form-item>
                                        </div>

                                    </el-collapse-item>
                                    <el-collapse-item title="关店" name="fourth">
                                        <div class="h sb">
                                            <el-form-item label="门店原名称" prop="oldName">
                                                <div style="width: 220px;">{{ form.oldName }}</div>
                                            </el-form-item>
                                            <el-form-item label="原门店编码" prop="oldCode">
                                                <div style="width: 220px;">{{ form.oldCode }}</div>
                                            </el-form-item>
                                            <el-form-item label="原门店形象" prop="oldStoreDesign">
                                                <div style="width: 220px;">{{ form.oldStoreDesign }}</div>
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="原综合形象" prop="oldOverallDesign">
                                                <div style="width: 220px;">{{ form.oldOverallDesign }}</div>
                                            </el-form-item>
                                            <el-form-item label="原门店面积" prop="oldUseArea">
                                                <div style="width: 220px;">{{ form.oldUseArea }}</div>
                                            </el-form-item>
                                            <el-form-item label="原门店详细地址" prop="oldAddress">
                                                <div style="width: 480px;">{{ form.oldAddress }}</div>
                                            </el-form-item>
                                        </div>
                                        <div class="h">
                                            <el-form-item label="关店日期" prop="closeDate">
                                                <div style="width: 220px;">{{
                                                    form.closeDate && new
                                                        Date(form.closeDate).format('yyyy/MM/dd')
                                                }}
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="关店类型" prop="closeType">
                                                <div style="width: 220px;">{{ form.closeType }}</div>
                                            </el-form-item>
                                            <el-form-item label="关店原因" prop="closeDesc">
                                                <div style="width: 220px;">{{ form.closeDesc }}</div>
                                            </el-form-item>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-tab-pane>
                            <el-tab-pane label="授权明细" name="2">
                                <el-table key="authDetail_0" :data="items || []" border style="width: 600px;">
                                    <el-table-column prop="createAt" label="创建日期"
                                        :formatter="v => { return v.createAt ? new Date(v.createAt).format('yyyy/MM/dd') : '' }" />
                                    <el-table-column prop="authBeginDate" label="授权开始日期 ~ 授权结束日期"
                                        :formatter="v => { return v.authBeginDate ? new Date(v.authBeginDate).format('yyyy/MM/dd') : '' }">
                                        <template slot-scope="scope">
                                            {{ new Date(scope.row.authBeginDate).format('yyyy/MM/dd') }} ~ {{
                                                new
                                                                                        Date(scope.row.authEndDate).format('yyyy/MM/dd')
                                            }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="关联仓库" name="3" v-if="!viewStore">
                                <el-form-item label="仓库" prop="warehouseName">
                                    <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName"
                                        url="api/warehouse/all" placeholder="选择仓库" clearable filterable
                                        style="width: 220px;" />
                                </el-form-item>
                            </el-tab-pane>
                        </el-tabs>
                    </template>
                    <template v-else>
                        <el-tabs v-model="activeName" tab-position="left">
                            <el-tab-pane label="门店信息" name="1">
                                <el-collapse v-model="baseNews">
                                    <el-collapse-item title="基本信息" name="first">
                                        <template v-if="viewStore">
                                            <div class="h">
                                                <el-form-item label="选择经销商" prop="entId">
                                                    <quick-select v-model="form.entId" url="api/distributor"
                                                        placeholder="经销商" clearable filterable
                                                        value-field="enterpriseId" style="width: 575px" />
                                                </el-form-item>
                                                <el-form-item label="是否启用" prop="enabled">
                                                    <el-switch v-model="form.enabled" active-color="#13ce66" />
                                                </el-form-item>
                                            </div>
                                        </template>
                                        <div class="h">
                                            <el-form-item label="所属物业" prop="propertyName">
                                                <el-input v-model="form.propertyName" style="width: 575px;" />
                                            </el-form-item>
                                            <el-form-item label="状态" prop="status">
                                                <el-input v-model="form.status" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <template v-if="$erp.enable()">
                                                <template v-if="!viewStore">
                                                    <el-form-item label="ERP账套" prop="erpAccountId">
                                                        <quick-select v-model="form.erpAccountId" value-field="id"
                                                            filterable url="api/shop/erp/account" placeholder="选择ERP账套"
                                                            style="width: 220px;" />
                                                    </el-form-item>
                                                    <el-form-item prop="erpCode" label="ERP编码">
                                                        <el-input v-model="form.erpCode" :maxlength="40"
                                                            @keypress.enter.native="handleQueryByERP"
                                                            @change="handleQueryByERP" :readonly="searching"
                                                            style="width: 220px;">
                                                            <el-button slot="append" icon="el-icon-search"
                                                                v-loading="searching" :disabled="!form.erpCode"
                                                                @click="handleQueryByERP"></el-button>
                                                        </el-input>
                                                    </el-form-item>
                                                    <el-form-item label="区域" prop="areaId">
                                                        <tree-picker v-model="form.areaId" url="api/area" flat
                                                            style="width: 220px;" />
                                                    </el-form-item>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店编码" prop="code">
                                                <el-input v-model="form.code" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="门店名称" prop="name">
                                                <el-input v-model="form.name" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="门店类型" prop="storeType">
                                                <el-input v-model="form.storeType" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店位置等级" prop="locationType">
                                                <el-input v-model="form.locationType" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="商场名称" prop="mall">
                                                <el-input v-model="form.mall" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="商场类型" prop="mallType">
                                                <el-input v-model="form.mallType" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="商场位置等级" prop="mallLocationType">
                                                <el-input v-model="form.mallLocationType" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="地点标识" prop="locationDesc">
                                                <el-input v-model="form.locationDesc" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="装修类型" prop="decorateType">
                                                <el-input v-model="form.decorateType" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店申请类型" prop="applyType">
                                                <el-input v-model="form.applyType" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="门店申请日期" prop="applyDate">
                                                <el-date-picker v-model="form.applyDate" value-format="timestamp"
                                                    style="width: 220px" />
                                            </el-form-item>
                                            <el-form-item label="计划开店日期" prop="planOpenDate">
                                                <el-date-picker v-model="form.planOpenDate" value-format="timestamp"
                                                    style="width: 220px" />
                                            </el-form-item>
                                        </div>
                                        <div class="h">
                                            <el-form-item label="门店形象" prop="storeDesign">
                                                <el-input v-model="form.storeDesign" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="综合形象" prop="overallDesign">
                                                <el-input v-model="form.overallDesign" style="width: 575px;" />
                                            </el-form-item>


                                        </div>
                                        <div class="h">
                                            <el-form-item label="门店申请总面积" prop="builtArea">
                                                <div class="h c" style="width: 220px;">
                                                    <el-input-number v-model="form.builtArea" :min="0" :max="99999"
                                                        :step="1" :precision="2" size="mini" controls-position="right"
                                                        class="flex" />
                                                    <span class="no-flex">&nbsp;㎡（建筑面积）</span>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="区域" prop="areaName" v-if="!$erp.enable()">
                                                <el-input v-model="form.areaName" style="width: 575px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h c">
                                            <el-form-item label="门店详细地址">
                                                <quick-select v-model="form.provinceCode"
                                                    :label.sync="form.provinceName" url="api/regionalDict/province"
                                                    value-field="code" placeholder="选择省份" filterable
                                                    style="width: 165px;"
                                                    @change="form.cityCode = null; form.districtCode = null;" />
                                            </el-form-item>
                                            <el-form-item label-width="10px" prop="cityCode">
                                                <quick-select v-model="form.cityCode" :label.sync="form.cityName"
                                                    :url="`api/regionalDict/parent/${form.provinceCode}`"
                                                    value-field="code" placeholder="选择城市" filterable
                                                    v-if="form.provinceCode" style="width: 165px;"
                                                    @change="form.districtCode = null" />
                                                <el-input disabled placeholder="请先选择省份" v-else style="width: 165px;" />
                                            </el-form-item>
                                            <el-form-item label-width="10px" prop="districtCode">
                                                <quick-select v-model="form.districtCode"
                                                    :label.sync="form.districtName"
                                                    :url="`api/regionalDict/parent/${form.cityCode}`" value-field="code"
                                                    placeholder="选择地区" filterable v-if="form.cityCode"
                                                    style="width: 165px;" />
                                                <el-input disabled placeholder="请先选择城市" v-else style="width: 165px;" />
                                            </el-form-item>
                                            <el-form-item label-width="10px" prop="address" class="flex">
                                                <el-input v-model="form.address" style="width: 100%;"
                                                    placeholder="具体到楼层门牌号" class="flex" />
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="经度" prop="lng">
                                                <el-input v-model="form.lng" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="纬度" prop="lat">
                                                <el-input v-model="form.lat" style="width: 220px;" />
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="城市类型" prop="cityType">
                                                <el-input v-model="form.cityType" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="区县类型" prop="districtType">
                                                <el-input v-model="form.districtType" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="门店月租金" prop="rent">
                                                <el-input-number v-model="form.rent" :min="0" :max="99999" :step="1"
                                                    :precision="2" size="mini" controls-position="right"
                                                    style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h">
                                            <el-form-item label="拓展人员" prop="expandPerson">
                                                <el-input v-model="form.expandPerson" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="运营人员" prop="managePerson">
                                                <el-input v-model="form.managePerson" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h c">
                                        <el-form-item label="提供除螨服务" prop="removeMiteService" style="width: 350px;">
                                            <el-switch v-model="form.removeMiteService"/>
                                        </el-form-item>
                                        <el-form-item label="除螨联系人" prop="removeMiteContact" v-if="form.removeMiteService">
                                            <el-input v-model="form.removeMiteContact" style="width: 220px;" />
                                        </el-form-item>
                                        <el-form-item label="除螨联系电话" prop="removeMiteTelephone" v-if="form.removeMiteService">
                                            <el-input v-model="form.removeMiteTelephone" style="width: 220px;" />
                                        </el-form-item>
                                    </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="开业" name="second">
                                        <el-form-item label="店面人员是否已参与公司培训" prop="isTrained" label-width="230px">
                                            <el-switch v-model="form.isTrained" />
                                        </el-form-item>

                                        <div class="h sb">
                                            <el-form-item label="导购配备人数" prop="salesmanNumber">
                                                <el-input-number v-model="form.salesmanNumber" :min="0" :max="99999"
                                                    :step="1" :precision="0" size="mini" controls-position="right"
                                                    style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="预计开业活动日期" prop="openPromotionDate">
                                                <el-date-picker v-model="form.openPromotionDate"
                                                    value-format="timestamp" style="width: 220px" />
                                            </el-form-item>
                                        </div>

                                        <div class="h sb">
                                            <!-- <el-form-item label="店面开业面积" prop="useArea">
                                              <el-input-number v-model="form.useArea" :min="0" :max="99999" :step="1"
                                                  :precision="2" size="mini" controls-position="right"
                                                  style="width: 220px;" />（使用面积）
                                          </el-form-item> -->


                                            <el-form-item label="店面开业面积" prop="useArea">
                                                <div class="h c" style="width: 220px;">
                                                    <el-input-number v-model="form.useArea" :min="0" :max="99999"
                                                        :step="1" :precision="2" size="mini" controls-position="right"
                                                        class="flex" />
                                                    <span class="no-flex">&nbsp;㎡（使用面积）</span>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="参与培训方式" prop="trainingMode">
                                                <el-input v-model="form.trainingMode" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                    </el-collapse-item>
                                    <el-collapse-item title="验收" name="third">

                                        <div class="h sb">
                                            <el-form-item label="开业日期" prop="openDate">
                                                <el-date-picker v-model="form.openDate" value-format="timestamp"
                                                    style="width: 220px" />
                                            </el-form-item>


                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="店内装修得分" prop="indoorScore">
                                                <el-input-number v-model="form.indoorScore" :min="0" :max="99999"
                                                    :step="1" :precision="2" size="mini" controls-position="right"
                                                    style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="独立外立面得分" prop="outdoorScore">
                                                <el-input-number v-model="form.outdoorScore" :min="0" :max="99999"
                                                    :step="1" :precision="2" size="mini" controls-position="right"
                                                    style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                        <div class="h sb">
                                            <el-form-item label="门店验收面积" prop="checkArea">
                                                <div class="h c" style="width: 220px;">
                                                    <el-input-number v-model="form.checkArea" :min="0" :max="99999"
                                                        :step="1" :precision="2" size="mini" controls-position="right"
                                                        class="flex" />
                                                    <span class="no-flex">&nbsp;㎡（使用面积）</span>
                                                </div>
                                            </el-form-item>
                                            <el-form-item label="独立外立面面积" prop="outdoorArea">
                                                <el-input-number v-model="form.outdoorArea" :min="0" :max="99999"
                                                    :step="1" :precision="2" size="mini" controls-position="right"
                                                    style="width: 220px;" />
                                            </el-form-item>
                                        </div>


                                    </el-collapse-item>
                                    <el-collapse-item title="关店" name="fourth">
                                        <div class="h sb">
                                            <el-form-item label="门店原名称" prop="oldName">
                                                <el-input v-model="form.oldName" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="原门店编码" prop="oldCode">
                                                <el-input v-model="form.oldCode" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="原门店形象" prop="oldStoreDesign">
                                                <el-input v-model="form.oldStoreDesign" style="width: 220px;" />
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="原综合形象" prop="oldOverallDesign">
                                                <el-input v-model="form.oldOverallDesign" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="原门店面积" prop="oldUseArea">
                                                <el-input-number v-model="form.oldUseArea" :min="0" :max="99999"
                                                    :step="1" :precision="2" size="mini" controls-position="right"
                                                    style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="原门店详细地址" prop="oldAddress">
                                                <el-input v-model="form.oldAddress" style="width: 220px;" />
                                            </el-form-item>

                                        </div>
                                        <div class="h">
                                            <el-form-item label="关店日期" prop="closeDate">
                                                <el-date-picker v-model="form.closeDate" value-format="timestamp"
                                                    style="width: 220px" />
                                            </el-form-item>
                                            <el-form-item label="关店类型" prop="closeType">
                                                <el-input v-model="form.closeType" style="width: 220px;" />
                                            </el-form-item>
                                            <el-form-item label="关店原因" prop="closeDesc">
                                                <el-input v-model="form.closeDesc" style="width: 220px;" />
                                            </el-form-item>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-tab-pane>
                            <el-tab-pane label="授权明细" name="2">
                                <el-table key="authDetail_0" :data="items || []" border style="width: 600px;">
                                    <el-table-column prop="createAt" label="创建日期"
                                        :formatter="v => { return v.createAt ? new Date(v.createAt).format('yyyy/MM/dd') : '' }" />
                                    <el-table-column prop="authBeginDate" label="授权开始日期 ~ 授权结束日期"
                                        :formatter="v => { return v.authBeginDate ? new Date(v.authBeginDate).format('yyyy/MM/dd') : '' }">
                                        <template slot-scope="scope">
                                            {{ new Date(scope.row.authBeginDate).format('yyyy/MM/dd') }} ~ {{
                                                new
                                                                                        Date(scope.row.authEndDate).format('yyyy/MM/dd')
                                            }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="关联仓库" name="3" v-if="!viewStore">
                                <el-form-item label="仓库" prop="warehouseName">
                                    <quick-select v-model="form.warehouseId" :label.sync="form.warehouseName"
                                        url="api/warehouse/all" placeholder="选择仓库" clearable filterable
                                        style="width: 220px;" />
                                </el-form-item>
                            </el-tab-pane>
                        </el-tabs>
                    </template>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="text" @click="dialog = false">取消</el-button>
                <template v-if="activeName == '1' || activeName == '2'">
                    <span v-if="checkPermission(['SHOPMANAGE_ALL', 'SHOPMANAGE_ADD'])">
                        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
                    </span>
                </template>
                <template v-else>
                    <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { add, edit, getByErpCode } from "@/api/shopInformation";
import { getStatementType } from "@/api/capitalPool";
import checkPermission from "@/utils/permission";
export default {
    props: {
        isAdd: {
            type: Boolean,
            required: true,
        },
    },
    mounted() {
        getStatementType().then((res) => {
            this.entType = res;
        });
    },
    data() {
        let checkHasErpIdError = (rule, val, callback) => {
            if (this.errors.erpCode) {
                callback("未找到指定的信息");
            } else {
                callback();
            }
        };
        return {
            viewStore: false,
            activeName: "1",
            baseNews: "first",
            loading: false,
            searching: false,
            dialog: false,
            disabled: false,
            entType: null,
            brandId: null,
            errors: {
                erpCode: false,
            },
            form: null,
            items: [],
            rules: {
                entId: [{ required: true, message: "请选择经销商" }],
                code: [{ required: true, message: "请输入门店编码" }],
                name: [{ required: true, message: "请输入门店名称" }],
                erpAccountId: [{ required: true, message: "请选择ERP账套" }],
                erpDeptCode: [{ required: true, message: "请输入ERP部门编码" }],
                erpCode: [
                    { required: true, message: "请输入ERP编码", trigger: "blur" },
                    { validator: checkHasErpIdError },
                ],
                provinceCode: [{ required: true, message: "请选择省份" }],
                cityCode: [{ required: true, message: "请选择城市" }],
                districtCode: [{ required: true, message: "请选择地区" }],
            },
        };
    },
    methods: {
        checkPermission,
        cancel() {
            this.form = null;
        },

        doSave() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.form));
                    // data.series = data.series.join(",");
                    // data.brand = data.brand.join(",");
                    // data.brandName = data.brandName.join(",");
                    this.loading = true;
                    if (this.isAdd) {
                        this.doAdd(data);
                    } else this.doEdit(data);
                }
            });
        },
        doAdd(data) {
            add(data)
                .then((res) => {
                    this.dialog = false;
                    this.$notify({
                        title: "保存成功",
                        type: "success",
                        duration: 2500,
                    });
                    this.loading = false;
                    this.$parent.init();
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err.response.data.message);
                });
        },
        doEdit(data) {
            edit(data)
                .then((res) => {
                    this.dialog = false;
                    this.$notify({
                        title: "修改成功",
                        type: "success",
                        duration: 2500,
                    });
                    this.loading = false;
                    this.$parent.init();
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err.response.data.message);
                });
        },
        restForm(form, items = null, viewStore = false) {
            this.dialog = true;
            this.disabled = false;
            this.activeName = "1";
            this.baseNews = "first";
            if (items) {
                this.items = items
            }
            if (viewStore) {
                this.viewStore = true
            } else {
                this.viewStore = false
            }
            this.form = form || {
                entId: null,
                enabled: false,//启用禁用
                erpAccountId: null,
                propertyName: null,
                erpCode: null,
                mall: null,
                mallType: null,
                mallLocationType: null,//商场位置等级
                locationDesc: null,//地点标识
                // distributorCode: null,//加盟商U9编码
                // distributorName: null,//加盟商名称
                decorateType: null,//装修类型
                areaName: null,//区域
                areaId: null,
                expandPerson: null,//拓展人员
                managePerson: null,//运营人员
                warehouseName: null,//仓库
                warehouseId: null,
                trainingMode: null,//参与培训方式
                cityType: null,//城市类型
                lng: null,
                lat: null,
                districtType: null,//区县类型
                provinceCode: null,
                provinceName: null,
                cityCode: null,
                cityName: null,
                districtCode: null,
                districtName: null,
                brandCode: null,//品牌
                code: null,//门店编码
                name: null,//门店名称
                storeType: null,//门店类型
                locationType: null,//门店位置等级

                storeDesign: null,//门店形象
                overallDesign: null,//综合形象

                salesmanNumber: null,//导购配备人数
                applyType: null,//门店申请类型
                applyDate: null,//门店申请日期
                planOpenDate: null,//计划开店日期
                openDate: null,//开业日期
                openPromotionDate: null,//预计开业活动日期
                checkArea: null,//门店验收面积㎡
                indoorScore: 0,//店内装修得分
                outdoorScore: 0,//独立外立面得分
                outdoorArea: 0,//独立外立面面积
                builtArea: null,//门店申请总面积㎡
                rent: null,//门店租金（含公摊）
                address: null,//门店详细地址
                isTrained: null,//店面人员是否已参与公司培训

                oldCode: null,//原门店编码
                oldStoreDesign: null,//原门店形象
                oldOverallDesign: null,//原综合形象
                oldUseArea: 0,//原门店面积
                oldAddress: null,//原门店详细地址
                closeDate: null,//关店日期
                closeType: null,//关店类型
                closeDesc: null,//关店原因


            };
        },
        handleQueryByERP() {
            if (this.form.erpCode) {
                this.searching = true;
                this.form.name = "";
                this.errors.erpCode = false;
                getByErpCode(this.form.erpCode)
                    .then((res) => {
                        this.form.name = res.alias;
                        this.errors.erpCode = false;
                    })
                    .catch((e) => {
                        this.errors.erpCode = true;
                    })
                    .finally((_) => {
                        this.searching = false;
                        this.$refs.form.validateField("erpCode");
                    });
            }
        },
    },
};
</script>

